interface InvestorProps {
  image: React.ReactNode
  link: string
}

export const Investor = ({ image, link }: InvestorProps) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" className="opacity-70 hover:opacity-100">
      {image}
    </a>
  )
}
