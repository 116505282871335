import clsx from 'clsx'
import { motion } from 'framer-motion'

interface AnimatedDividerProps {
  color?: string
  className?: string
}

export const AnimatedDivider = ({ className, color = 'rgba(136,180,245,0.5)' }: AnimatedDividerProps) => {
  const initialGradient = `linear-gradient(90deg, ${color} 0%, rgba(0,0,0,0.35) 50%)`
  const finalGradient1 = `linear-gradient(90deg, ${color} 0%, rgba(0,0,0,0.35) 50%)`
  const finalGradient2 = `linear-gradient(90deg, rgba(0,0,0,0) 50%, ${color} 100%)`

  return (
    <motion.div className="relative">
      <motion.hr
        initial={{
          background: `${initialGradient}}`,
        }}
        animate={{
          background: [finalGradient1, finalGradient2],
        }}
        transition={{
          duration: 8,
          ease: 'linear',
          repeat: Infinity,
          repeatDelay: 0,
          repeatType: 'mirror',
          times: [0, 1],
        }}
        className={clsx('bg-[length:200%_100%] bg-[0%_50%] h-0.5 w-full border-none', className)}
      />
      <motion.div
        initial={{
          transform: 'translateX(-15%)',
        }}
        animate={{
          transform: 'translateX(115%)',
        }}
        transition={{
          duration: 8,
          ease: 'linear',
          repeat: Infinity,
          repeatDelay: 0,
          repeatType: 'mirror',
          times: [0, 1],
        }}
        className={clsx('absolute -top-1/2 -translate-y-1/2 left-0 w-1/2 h-1 blur-[1px]')}
        style={{
          background: `radial-gradient(45.93% 100% at 50% 50%, ${color} 0, transparent 100%)`,
        }}
      />
    </motion.div>
  )
}
