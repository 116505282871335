'use client'

import Image from 'next/image'

import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import { Nullish, ResponsiveSide } from '../types'

import { EthenaTooltip } from './EthenaTooltip'
import Placeholder from './Placeholder'

export interface StatProps {
  classes?: string
  valueWeight?: 'font-medium' | 'font-semibold' | 'font-bold'
  labelWeight?: 'font-medium' | 'font-semibold' | 'font-bold'
  fontSize?: string
  label: ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left' | ResponsiveSide
  margin?: string
  stat?: Nullish<string>
  tooltip?: string | React.ReactNode
  padding?: string
  gap?: 'gap-1' | 'gap-2'
  tooltipWidth?: string
}

export const Stat: FC<StatProps> = ({
  classes = 'bg-[#121216]',
  fontSize = 'text-xs',
  gap = 'gap-2',
  label,
  labelWeight = 'font-medium',
  margin = 'mx-1',
  padding = 'px-2.5 py-1.5',
  side,
  stat,
  tooltip,
  tooltipWidth = 'w-[200px]',
  valueWeight = 'font-semibold',
}) => {
  return (
    <div
      className={clsx(
        'flex items-center whitespace-nowrap rounded leading-4 text-white',
        margin,
        gap,
        fontSize,
        labelWeight,
        classes,
        padding,
      )}
    >
      <span>{label}</span>
      <span className={clsx(valueWeight, 'text-cblue-400')}>
        <Placeholder isLoading={!stat} classes="w-[42px] h-4">
          {stat}
        </Placeholder>
      </span>
      {tooltip && (
        <EthenaTooltip width={tooltipWidth} padding="p-3" content={tooltip} side={side}>
          <Image src="/shared/info-round.svg" alt="Info icon" width={14} height={14} className="opacity-60" />
        </EthenaTooltip>
      )}
    </div>
  )
}
