'use client'

import clsx from 'clsx'

interface InvestorRowProps {
  children: React.ReactNode
  gap?: string
  margin?: string
  align?: string
  classes?: string
}

export const InvestorsRow = ({
  align = 'items-center',
  children,
  classes = 'flex',
  gap = 'gap-[18px]',
  margin = '',
}: InvestorRowProps) => {
  return (
    <div className={clsx(gap, 'w-full lg:justify-between justify-start flex-wrap', align, margin, classes)}>
      {children}
    </div>
  )
}
