import BinanceIcon from '../public/investors/binance-labs.svg'
import BybitIcon from '../public/investors/bybit.svg'
import DeribitIcon from '../public/investors/deribit.svg'
import DragonflyIcon from '../public/investors/dragonfly.svg'
import FidelityIcon from '../public/investors/fidelity.svg'
import FranklintempletonIcon from '../public/investors/franklintempleton.svg'
import OkxIcon from '../public/investors/okx.svg'
import WintermuteIcon from '../public/investors/wintermute.svg'

export const INVESTORS = [
  {
    image: <DragonflyIcon />,
    link: 'https://www.dragonfly.xyz/',
  },
  {
    image: <BinanceIcon />,
    link: 'https://www.binance.com/',
  },
  {
    image: <BybitIcon />,
    link: 'https://www.bybit.com/',
  },
  {
    image: <OkxIcon />,
    link: 'https://www.okx.com/',
  },
  {
    image: <DeribitIcon />,
    link: 'https://www.deribit.com/',
  },
  {
    image: <FidelityIcon />,
    link: 'https://www.fidelity.com/',
  },
  {
    image: <FranklintempletonIcon />,
    link: 'https://www.franklintempleton.com/',
  },
  {
    image: <WintermuteIcon />,
    link: 'https://www.wintermute.com/',
  },
] satisfies {
  image: React.ReactNode
  link: string
}[]
